import React from "react"
import Message from "../Message"
import Recaptcha from "react-recaptcha"
import Button from "../Button"
import axios from "axios"
import S3 from "../S3"

import "./styles.scss"
import ProgressBar from "../ProgressBar";

const S3Config = {
    bucketName: process.env.AWS_BUCKET_NAME,
    region: process.env.AWS_REGION,
    accessKeyId: process.env.AWS_ACCESS_KEY,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY
}

const formAPI = process.env.API_CONTACT_FORM
let recaptchaInstance;

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            fileName: "Upload File (Optional)",
            disableSubmit: false,
            showMessage: false,
        }
    }

    validateInputs = () => {
        let ok = true;
        this.setState({errors: {}})
        if (!this.state.name) {
            this.setState(({errors}) => ({errors: {
            ...errors,
            name: "Please enter a valid name",
            }}))
            ok = false;
        }
        if (!this.state.reCaptcha) {
            this.setState(({errors}) => ({errors: {
            ...errors,
            reCaptcha: "Please verity you are a human",
            }}))
            ok = false;
        }
        if (!this.isEmail(this.state.email)) {
            this.setState(({errors}) => ({errors: {
            ...errors,
            email: "Please enter a valid e-mail",
            }}))
            ok = false;
        }
        if (this.state.errors.file) {
            ok = false;
        }
        return ok;
    }

    isEmail = e => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(e);
    }

    uploadFile = async (e) => {
        const S3UploadParams = S3.makeUploadParams(this.state.file, S3Config);
        await axios.post(S3UploadParams.url, S3UploadParams.body, {
            onUploadProgress: (progressEvent) => {
                let progress = progressEvent.loaded / progressEvent.total * 100
                this.setState({
                    uploadProgress: Math.round(progress),
                    showProgress: true,
                })
            },
        })
        .then(response => {
            this.setState({
                fileLocation: `${S3UploadParams.url}${this.state.file.name}`
            })
        })
        .catch(error => {
            this.setState({
                uploadProgress: 0,
                showProgress: false,
                disableSubmit: false
            })
            if (error) {
                this.setState({
                    showMessage: true,
                    message: "Error uploading file",
                    type: "danger"
                })
            }
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.validateInputs() === false) {
            return
        } else {
            this.setState({
                disableSubmit: true,
                showMessage: false
            })
            if (this.state.file) {
                await this.uploadFile()
            }
            axios.post(formAPI,
            {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                enquiry: this.state.enquiry,
                reCaptcha: this.state.reCaptcha,
                fileLocation: this.state.fileLocation
            })
            .then(response => {
                this.setState({
                    uploadProgress: 0,
                    showProgress: false,
                    disableSubmit: false,
                    showMessage: true,
                    message: response.data.message,
                    type: "success"
                })
                this.resetRecaptcha()
            })
            .catch(error => {
                this.setState({
                    uploadProgress: 0,
                    showProgress: false,
                    disableSubmit: false
                })
                if (error.response) {
                    this.setState({
                        showMessage: true,
                        message: error.response.data.message,
                        type: "danger"
                    })
                    this.resetRecaptcha()
                } else if (error.request) {
                    this.setState({
                        showMessage: true,
                        message: "Server Error",
                        type: "danger"
                    })
                    this.resetRecaptcha()
                }
            })
        }
    }

    handleChange = e => this.setState({ [e.target.name]: e.target.value })

    handleFile = e => {
        if(!e.target.files[0]) {
            return
        }
        const newState = this.state.errors
        delete newState.file
        this.setState(({errors}) => ({newState}))

        const fileName = e.target.files[0].name;
        var fileExtension = fileName.substring(fileName.lastIndexOf('.')+1, fileName.length) || fileName;

        const acceptedFileExtensions = ["jpg", "jpeg", "png", "mov", "mp4"];

        if (!acceptedFileExtensions.includes(fileExtension)) {
            this.setState(({errors}) => ({errors: {
            ...errors,
            file: "Not a valid filetype",
            }}))
            return
        }

        const fileSize = (e.target.files[0].size /1024 / 1024).toFixed(2);

        if (fileSize > 100) {
            this.setState(({errors}) => ({errors: {
            ...errors,
            file: "Filesize is too large",
            }}))
            return
        }

        this.setState({
            file: e.target.files[0],
            fileName: e.target.files[0].name
        })

    }

    verifyReCaptcha = response => {
        this.setState({"reCaptcha": response})
    }

    resetRecaptcha = () => recaptchaInstance.reset();  

    render() {
        return(
            <>
            <section className="container">
                <form type="multipart/form-data" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-lg-6 form-col-1">
                                    <div className="form-group">
                                        <input onChange={this.handleChange} name="name" className={this.state.errors.name ? "form-control is-invalid" : "form-control"} id="name" aria-describedby="nameHelp" placeholder="Name" />
                                        <Feedback message={this.state.errors.name} />
                                    </div>
                                    <div className="form-group">
                                        <input onChange={this.handleChange} name="email" className={this.state.errors.email ? "form-control is-invalid" : "form-control"}  id="email" aria-describedby="emailHelp" placeholder="E-Mail" />
                                        <Feedback message={this.state.errors.email} />
                                    </div>
                                    <div className="form-group">
                                        <input onChange={this.handleChange} name="phone" className="form-control" id="phone" aria-describedby="phoneHelp" placeholder="Phone" />
                                    </div>
                                    <div className="form-group">
                                        <div className="custom-file">
                                            <input onChange={this.handleFile} type="file" name="file" className="custom-file-input" id="file" accept=".jpg, .jpeg, .png, .mov, .mp4" />
                                            <label className="custom-file-label" htmlFor="file">{this.state.fileName}</label>
                                        </div>
                                        <Feedback message={this.state.errors.file} />
                                    </div>
                                </div>
                                <div className="col-lg-6 form-col-2">
                                    <div className="form-group">
                                        <textarea onChange={this.handleChange} name="enquiry" className="form-control" id="enquiry" rows="6" placeholder="Message"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <Recaptcha
                                            ref={e => recaptchaInstance = e}
                                            sitekey={process.env.GOOGLE_RECAPTCHA_KEY}
                                            verifyCallback={this.verifyReCaptcha}
                                        />
                                    <Feedback message={this.state.errors.reCaptcha} />
                                    </div>
                                    <div className="form-group">
                                        <ProgressBar show={this.state.showProgress} progress={this.state.uploadProgress} />
                                        <Message show={this.state.showMessage} type={this.state.type} message={this.state.message} />
                                    </div>
                                    <div className="form-group">
                                        <Button bsStyle="primary" type="submit" disabled={this.state.disableSubmit} text="Send" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 form-col-3">
                            <h3>Operating Hours</h3>
                            <p>Monday - Friday : 8am - 4pm<br/>
                               Saturday - Sunday : Closed</p>
                        </div>
                    </div>
                </form>
            </section>
            </>
        )
    }
}

const Feedback = ({message}) => {
    return (
        <div className="form-invalid">
            {message}
        </div>
    )
}

export default ContactForm