import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import ContactMap from "../components/ContactMap"
import Header from "../components/Header"

const Contact = ({location}) => (
  <Layout location={location}>
    <SEO title="Contact" />
    <Header />
    <section className="container page">
      <div className="row">
        <div className="col-12 text-center">
          <h1>Contact Us</h1>
        </div>
      </div>
    </section>
    <ContactMap enquire="false" />
    <ContactForm />
  </Layout>
)

export default Contact