import Signature from "./Signature";
import Policy from "./Policy";
import { xAmzDate, dateYMD } from "./Date";

class S3 {

    static makeUploadParams (file, config) {
        const fd = new FormData();
        const key = `${config.dirName ? config.dirName + "/" : ""}${file.name}`;
        const url = `https://${config.bucketName}.s3-${config.region}.amazonaws.com/`;
        fd.append("key", key);
        fd.append("acl", "public-read");
        fd.append("Content-Type", file.type);
        fd.append("x-amz-meta-uuid", "14365123651274");
        fd.append("x-amz-server-side-encryption", "AES256");
        fd.append("X-Amz-Credential", `${config.accessKeyId}/${dateYMD}/${config.region}/s3/aws4_request`);
        fd.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
        fd.append("X-Amz-Date", xAmzDate);
        fd.append("x-amz-meta-tag", "");
        fd.append("Policy", Policy.getPolicy(config));
        fd.append("X-Amz-Signature", Signature.getSignature(config, dateYMD, Policy.getPolicy(config)));
        fd.append("file", file);

        return {
            url: url,
            method: "POST",
            headers: {
                fd
            },
            body: fd
        }
    }

    // Not Finished...
    static makeDeleteParams (fileName, config) {
        const fd = new FormData();
        const url = `https://${config.bucketName}.s3-${config.region}.amazonaws.com/${config.dirName ? config.dirName + "/" : ""}${fileName}`;
        fd.append("Date", xAmzDate);
        fd.append("X-Amz-Date", xAmzDate);
        fd.append("Authorization", Signature.getSignature(config, dateYMD, Policy.getPolicy(config)));
        fd.append("Content-Type", "text/plain");

        return {
            url: url,
            Auth: "AWS " + config.accessKeyId + ":" +Signature.getSignature(config, dateYMD, Policy.getPolicy(config)),
            xDate: xAmzDate
        };
    }
    
}

export default S3;