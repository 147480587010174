import React from "react"

const ProgressBar = props => {
    if(props.show) {
        return(
            <div className="progress">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={props.rogress} aria-valuemin="0" aria-valuemax="100" style={{width: props.progress + '%'}} >
                    {props.rogress}
                </div>
            </div>
        )
    } else {
        return(null)
    }
}

export default ProgressBar