import React from "react"

import "./styles.scss"

const Message = ({show, type, message}) => {
    if (show === true) {
        return(
            <div className={`text-center alert alert-${type}`}>
                {message}
            </div>
        )
    } else {
        return(null)
    }
}

export default Message